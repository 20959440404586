/**
 * SmartCosting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BodyEvaluateFormulaCostingEvaluateFormulaPost } from '../model/bodyEvaluateFormulaCostingEvaluateFormulaPost';
// @ts-ignore
import { HTTPValidationError } from '../model/hTTPValidationError';
// @ts-ignore
import { ProcessCreate } from '../model/processCreate';
// @ts-ignore
import { ProcessReorderList } from '../model/processReorderList';
// @ts-ignore
import { ProjectOptionCreate } from '../model/projectOptionCreate';
// @ts-ignore
import { ReferenceFileSettings } from '../model/referenceFileSettings';
// @ts-ignore
import { ResponseModelFormula } from '../model/responseModelFormula';
// @ts-ignore
import { ResponseModelFormulaResult } from '../model/responseModelFormulaResult';
// @ts-ignore
import { ResponseModelLayerList } from '../model/responseModelLayerList';
// @ts-ignore
import { ResponseModelProcess } from '../model/responseModelProcess';
// @ts-ignore
import { ResponseModelProcessList } from '../model/responseModelProcessList';
// @ts-ignore
import { ResponseModelProcessReorderList } from '../model/responseModelProcessReorderList';
// @ts-ignore
import { ResponseModelProjectOption } from '../model/responseModelProjectOption';
// @ts-ignore
import { ResponseModelReferenceFileProperties } from '../model/responseModelReferenceFileProperties';
// @ts-ignore
import { ResponseModelSimilarItemList } from '../model/responseModelSimilarItemList';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateProcessCostingProjectOptionsProjectOptionUuidProcessPostRequestParams {
    projectOptionUuid: string;
    processCreate: ProcessCreate;
}

export interface CreateProjectOptionCostingProjectsProjectUuidProjectOptionsPostRequestParams {
    /** The UUID of the project */
    projectUuid: string;
    projectOptionCreate: ProjectOptionCreate;
}

export interface DeleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDeleteRequestParams {
    projectOptionUuid: string;
    processUuid: string;
}

export interface DetectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPostRequestParams {
    /** The UUID of the project Option */
    projectOptionUuid: string;
    referenceFileSettings: ReferenceFileSettings;
}

export interface EvaluateFormulaCostingEvaluateFormulaPostRequestParams {
    formulaUuid: string;
    optionUuid: string;
    bodyEvaluateFormulaCostingEvaluateFormulaPost: BodyEvaluateFormulaCostingEvaluateFormulaPost;
}

export interface GetProcessesCostingProjectOptionsProjectOptionUuidProcessesGetRequestParams {
    projectOptionUuid: string;
    mainprocess?: string | null;
}

export interface GetProjectOptionCostingProjectOptionsProjectOptionUuidGetRequestParams {
    projectOptionUuid: string;
}

export interface GetSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGetRequestParams {
    projectOptionUuid: string;
    mainprocess: 'FOR' | 'SIZ';
}

export interface ReadFormulasCostingFormulaGetRequestParams {
    site: string;
    workcenterUuid: string;
    taskUuid: string;
}

export interface ReorderProcessesCostingReorderProcessesPostRequestParams {
    processReorderList: ProcessReorderList;
}

export interface UpdateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPutRequestParams {
    projectOptionUuid: string;
    processUuid: string;
    processCreate: ProcessCreate;
}

export interface UpdateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatchRequestParams {
    /** The UUID of the project Option */
    projectOptionUuid: string;
    /** The new name for the project option */
    newName?: string;
    /** The new name for the project option */
    skipSizing?: boolean;
}

export interface UploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePostRequestParams {
    /** The UUID of the project Option */
    projectOptionUuid: string;
    file: Blob;
    density?: number;
    pressForce?: number;
}


@Injectable({
  providedIn: 'root'
})
export class CostingProcessService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create Process
     * Create a new process
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProcessCostingProjectOptionsProjectOptionUuidProcessPost(requestParameters?: CreateProcessCostingProjectOptionsProjectOptionUuidProcessPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProcess>;
    public createProcessCostingProjectOptionsProjectOptionUuidProcessPost(requestParameters?: CreateProcessCostingProjectOptionsProjectOptionUuidProcessPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProcess>>;
    public createProcessCostingProjectOptionsProjectOptionUuidProcessPost(requestParameters?: CreateProcessCostingProjectOptionsProjectOptionUuidProcessPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProcess>>;
    public createProcessCostingProjectOptionsProjectOptionUuidProcessPost(requestParameters?: CreateProcessCostingProjectOptionsProjectOptionUuidProcessPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling createProcessCostingProjectOptionsProjectOptionUuidProcessPost.');
        }
        const processCreate = requestParameters?.processCreate;
        if (processCreate === null || processCreate === undefined) {
            throw new Error('Required parameter processCreate was null or undefined when calling createProcessCostingProjectOptionsProjectOptionUuidProcessPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/process`;
        return this.httpClient.request<ResponseModelProcess>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: processCreate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Project Option
     * Step 1
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectOptionCostingProjectsProjectUuidProjectOptionsPost(requestParameters?: CreateProjectOptionCostingProjectsProjectUuidProjectOptionsPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProjectOption>;
    public createProjectOptionCostingProjectsProjectUuidProjectOptionsPost(requestParameters?: CreateProjectOptionCostingProjectsProjectUuidProjectOptionsPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProjectOption>>;
    public createProjectOptionCostingProjectsProjectUuidProjectOptionsPost(requestParameters?: CreateProjectOptionCostingProjectsProjectUuidProjectOptionsPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProjectOption>>;
    public createProjectOptionCostingProjectsProjectUuidProjectOptionsPost(requestParameters?: CreateProjectOptionCostingProjectsProjectUuidProjectOptionsPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectUuid = requestParameters?.projectUuid;
        if (projectUuid === null || projectUuid === undefined) {
            throw new Error('Required parameter projectUuid was null or undefined when calling createProjectOptionCostingProjectsProjectUuidProjectOptionsPost.');
        }
        const projectOptionCreate = requestParameters?.projectOptionCreate;
        if (projectOptionCreate === null || projectOptionCreate === undefined) {
            throw new Error('Required parameter projectOptionCreate was null or undefined when calling createProjectOptionCostingProjectsProjectUuidProjectOptionsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/projects/${this.configuration.encodeParam({name: "projectUuid", value: projectUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/project_options/`;
        return this.httpClient.request<ResponseModelProjectOption>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: projectOptionCreate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Process
     * Delete a process
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete(requestParameters?: DeleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProcess>;
    public deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete(requestParameters?: DeleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProcess>>;
    public deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete(requestParameters?: DeleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProcess>>;
    public deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete(requestParameters?: DeleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete.');
        }
        const processUuid = requestParameters?.processUuid;
        if (processUuid === null || processUuid === undefined) {
            throw new Error('Required parameter processUuid was null or undefined when calling deleteProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/process/${this.configuration.encodeParam({name: "processUuid", value: processUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ResponseModelProcess>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Detect Layers
     * Step 2 - detect layers
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost(requestParameters?: DetectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelLayerList>;
    public detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost(requestParameters?: DetectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelLayerList>>;
    public detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost(requestParameters?: DetectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelLayerList>>;
    public detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost(requestParameters?: DetectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost.');
        }
        const referenceFileSettings = requestParameters?.referenceFileSettings;
        if (referenceFileSettings === null || referenceFileSettings === undefined) {
            throw new Error('Required parameter referenceFileSettings was null or undefined when calling detectLayersCostingProjectOptionsProjectOptionUuidReferenceFileLayersPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/reference_file/layers`;
        return this.httpClient.request<ResponseModelLayerList>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: referenceFileSettings,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Evaluate Formula
     * Evaluate Formula for Given Parameters
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public evaluateFormulaCostingEvaluateFormulaPost(requestParameters?: EvaluateFormulaCostingEvaluateFormulaPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelFormulaResult>;
    public evaluateFormulaCostingEvaluateFormulaPost(requestParameters?: EvaluateFormulaCostingEvaluateFormulaPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelFormulaResult>>;
    public evaluateFormulaCostingEvaluateFormulaPost(requestParameters?: EvaluateFormulaCostingEvaluateFormulaPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelFormulaResult>>;
    public evaluateFormulaCostingEvaluateFormulaPost(requestParameters?: EvaluateFormulaCostingEvaluateFormulaPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const formulaUuid = requestParameters?.formulaUuid;
        if (formulaUuid === null || formulaUuid === undefined) {
            throw new Error('Required parameter formulaUuid was null or undefined when calling evaluateFormulaCostingEvaluateFormulaPost.');
        }
        const optionUuid = requestParameters?.optionUuid;
        if (optionUuid === null || optionUuid === undefined) {
            throw new Error('Required parameter optionUuid was null or undefined when calling evaluateFormulaCostingEvaluateFormulaPost.');
        }
        const bodyEvaluateFormulaCostingEvaluateFormulaPost = requestParameters?.bodyEvaluateFormulaCostingEvaluateFormulaPost;
        if (bodyEvaluateFormulaCostingEvaluateFormulaPost === null || bodyEvaluateFormulaCostingEvaluateFormulaPost === undefined) {
            throw new Error('Required parameter bodyEvaluateFormulaCostingEvaluateFormulaPost was null or undefined when calling evaluateFormulaCostingEvaluateFormulaPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (formulaUuid !== undefined && formulaUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>formulaUuid, 'formula_uuid');
        }
        if (optionUuid !== undefined && optionUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>optionUuid, 'option_uuid');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/evaluate_formula`;
        return this.httpClient.request<ResponseModelFormulaResult>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: bodyEvaluateFormulaCostingEvaluateFormulaPost,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Processes
     * Step &gt;4 - Fetch processes
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(requestParameters?: GetProcessesCostingProjectOptionsProjectOptionUuidProcessesGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProcessList>;
    public getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(requestParameters?: GetProcessesCostingProjectOptionsProjectOptionUuidProcessesGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProcessList>>;
    public getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(requestParameters?: GetProcessesCostingProjectOptionsProjectOptionUuidProcessesGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProcessList>>;
    public getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet(requestParameters?: GetProcessesCostingProjectOptionsProjectOptionUuidProcessesGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling getProcessesCostingProjectOptionsProjectOptionUuidProcessesGet.');
        }
        const mainprocess = requestParameters?.mainprocess;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (mainprocess !== undefined && mainprocess !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mainprocess, 'mainprocess');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/processes`;
        return this.httpClient.request<ResponseModelProcessList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Project Option
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectOptionCostingProjectOptionsProjectOptionUuidGet(requestParameters?: GetProjectOptionCostingProjectOptionsProjectOptionUuidGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProjectOption>;
    public getProjectOptionCostingProjectOptionsProjectOptionUuidGet(requestParameters?: GetProjectOptionCostingProjectOptionsProjectOptionUuidGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProjectOption>>;
    public getProjectOptionCostingProjectOptionsProjectOptionUuidGet(requestParameters?: GetProjectOptionCostingProjectOptionsProjectOptionUuidGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProjectOption>>;
    public getProjectOptionCostingProjectOptionsProjectOptionUuidGet(requestParameters?: GetProjectOptionCostingProjectOptionsProjectOptionUuidGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling getProjectOptionCostingProjectOptionsProjectOptionUuidGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ResponseModelProjectOption>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Similar Items
     * Step 3 and 4 - Fetch similar items for FORMING and SIZING
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet(requestParameters?: GetSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelSimilarItemList>;
    public getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet(requestParameters?: GetSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelSimilarItemList>>;
    public getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet(requestParameters?: GetSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelSimilarItemList>>;
    public getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet(requestParameters?: GetSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet.');
        }
        const mainprocess = requestParameters?.mainprocess;
        if (mainprocess === null || mainprocess === undefined) {
            throw new Error('Required parameter mainprocess was null or undefined when calling getSimilarItemsCostingProjectOptionsProjectOptionUuidSimilarItemsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (mainprocess !== undefined && mainprocess !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mainprocess, 'mainprocess');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/similar_items`;
        return this.httpClient.request<ResponseModelSimilarItemList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read Formulas
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readFormulasCostingFormulaGet(requestParameters?: ReadFormulasCostingFormulaGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelFormula>;
    public readFormulasCostingFormulaGet(requestParameters?: ReadFormulasCostingFormulaGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelFormula>>;
    public readFormulasCostingFormulaGet(requestParameters?: ReadFormulasCostingFormulaGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelFormula>>;
    public readFormulasCostingFormulaGet(requestParameters?: ReadFormulasCostingFormulaGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const site = requestParameters?.site;
        if (site === null || site === undefined) {
            throw new Error('Required parameter site was null or undefined when calling readFormulasCostingFormulaGet.');
        }
        const workcenterUuid = requestParameters?.workcenterUuid;
        if (workcenterUuid === null || workcenterUuid === undefined) {
            throw new Error('Required parameter workcenterUuid was null or undefined when calling readFormulasCostingFormulaGet.');
        }
        const taskUuid = requestParameters?.taskUuid;
        if (taskUuid === null || taskUuid === undefined) {
            throw new Error('Required parameter taskUuid was null or undefined when calling readFormulasCostingFormulaGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (site !== undefined && site !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>site, 'site');
        }
        if (workcenterUuid !== undefined && workcenterUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>workcenterUuid, 'workcenter_uuid');
        }
        if (taskUuid !== undefined && taskUuid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>taskUuid, 'task_uuid');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/formula/`;
        return this.httpClient.request<ResponseModelFormula>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reorder Processes
     * Reorder a list of processes
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reorderProcessesCostingReorderProcessesPost(requestParameters?: ReorderProcessesCostingReorderProcessesPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProcessReorderList>;
    public reorderProcessesCostingReorderProcessesPost(requestParameters?: ReorderProcessesCostingReorderProcessesPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProcessReorderList>>;
    public reorderProcessesCostingReorderProcessesPost(requestParameters?: ReorderProcessesCostingReorderProcessesPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProcessReorderList>>;
    public reorderProcessesCostingReorderProcessesPost(requestParameters?: ReorderProcessesCostingReorderProcessesPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const processReorderList = requestParameters?.processReorderList;
        if (processReorderList === null || processReorderList === undefined) {
            throw new Error('Required parameter processReorderList was null or undefined when calling reorderProcessesCostingReorderProcessesPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/reorder_processes`;
        return this.httpClient.request<ResponseModelProcessReorderList>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: processReorderList,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Process
     * Update an existing process
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut(requestParameters?: UpdateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProcess>;
    public updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut(requestParameters?: UpdateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProcess>>;
    public updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut(requestParameters?: UpdateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProcess>>;
    public updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut(requestParameters?: UpdateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut.');
        }
        const processUuid = requestParameters?.processUuid;
        if (processUuid === null || processUuid === undefined) {
            throw new Error('Required parameter processUuid was null or undefined when calling updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut.');
        }
        const processCreate = requestParameters?.processCreate;
        if (processCreate === null || processCreate === undefined) {
            throw new Error('Required parameter processCreate was null or undefined when calling updateProcessCostingProjectOptionsProjectOptionUuidProcessProcessUuidPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/process/${this.configuration.encodeParam({name: "processUuid", value: processUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<ResponseModelProcess>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: processCreate,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Project Option
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatch(requestParameters?: UpdateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelProjectOption>;
    public updateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatch(requestParameters?: UpdateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelProjectOption>>;
    public updateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatch(requestParameters?: UpdateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelProjectOption>>;
    public updateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatch(requestParameters?: UpdateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling updateProjectOptionCostingProjectOptionsProjectOptionUuidUpdatePatch.');
        }
        const newName = requestParameters?.newName;
        const skipSizing = requestParameters?.skipSizing;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (newName !== undefined && newName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>newName, 'new_name');
        }
        if (skipSizing !== undefined && skipSizing !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>skipSizing, 'skip_sizing');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/update`;
        return this.httpClient.request<ResponseModelProjectOption>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload Reference File
     * Step 2 - upload a reference file
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost(requestParameters?: UploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ResponseModelReferenceFileProperties>;
    public uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost(requestParameters?: UploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ResponseModelReferenceFileProperties>>;
    public uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost(requestParameters?: UploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ResponseModelReferenceFileProperties>>;
    public uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost(requestParameters?: UploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectOptionUuid = requestParameters?.projectOptionUuid;
        if (projectOptionUuid === null || projectOptionUuid === undefined) {
            throw new Error('Required parameter projectOptionUuid was null or undefined when calling uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost.');
        }
        const file = requestParameters?.file;
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadReferenceFileCostingProjectOptionsProjectOptionUuidReferenceFilePost.');
        }
        const density = requestParameters?.density;
        const pressForce = requestParameters?.pressForce;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }
        if (density !== undefined) {
            localVarFormParams = localVarFormParams.append('density', <any>density) as any || localVarFormParams;
        }
        if (pressForce !== undefined) {
            localVarFormParams = localVarFormParams.append('press_force', <any>pressForce) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/costing/project_options/${this.configuration.encodeParam({name: "projectOptionUuid", value: projectOptionUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/reference_file`;
        return this.httpClient.request<ResponseModelReferenceFileProperties>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
