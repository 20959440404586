import {inject, Injectable, signal} from '@angular/core';
import {AdminSectionService, Constant, ResponseModelConstantList} from "../../api/auto-gen";
import {AdminPageFilterService} from "./admin-page-filter.service";
import {ToastService} from "../../shared/services/toast-service.service";

@Injectable()
export class ConstantsService {
  constants = signal<Constant[]>([]);
  adminSectionService = inject(AdminSectionService);
  adminPageFilterService = inject(AdminPageFilterService);
  toastService = inject(ToastService);

  fetchConstants() {
    this.adminSectionService.readConstantsAdminConstantsGet({
      site: this.adminPageFilterService.selectedSite(),
      mainprocess: this.adminPageFilterService.selectedMainProcess(),
    })
      .pipe()
      .subscribe({
          next: (response: ResponseModelConstantList) => {
            this.constants.set(response.data?.constants ?? []);
          },
          error: (error: any) => {
            this.toastService.pushErrorToast(error);
          },
        },
      );
  }
}
